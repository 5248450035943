<template>
    <div role="tablist">
        <b-card no-body class="p-1 mb-2">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button @click="applyFilters" variant="primary">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light"><b-icon-filter/>Фильтры</b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(сбросить)</b-link>
                    </small>
                </b-col>
            </b-row>

            <b-collapse id="filters">
                <b-card-body>
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group description="Дата (от)">
                                <datetime @input="clearPage()" type="datetime" class="form-control no-border" v-model="filters.dateFrom"></datetime>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group description="Дата (до)">
                                <datetime @input="clearPage()" type="datetime" class="form-control no-border" v-model="filters.dateTo"></datetime>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group description="Отображать только акты, требующие приемки">
                                <b-checkbox @input="clearPage()" v-model="filters.notReceivedOnly">Непринятые</b-checkbox>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group description="Откуда">
                                <storage-picker @input="clearPage()" :allowed-roles="null" v-model="filters.storageFrom" :nullable="true"></storage-picker>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group description="Куда">
                                <storage-picker @input="clearPage()" v-model="filters.storageTo" :nullable="true"></storage-picker>
                            </b-form-group>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <div v-if="data && data.length">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead class="thead-dark">
                    <tr>
                        <th>№</th>
                        <th>Дата</th>
                        <th>Номенклатура</th>
                        <th>Откуда</th>
                        <th>Куда</th>
                        <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="act in data" @dblclick="toForm(act)" :class="{'alert-success': act.received}">
                        <td>{{act.id}}</td>
                        <td>{{moment(act.date).format('DD.MM.YYYY HH:mm')}}</td>
                        <td>{{act.preview ? act.preview.name : '-'}}</td>
                        <td>{{act.storageFrom.name}}</td>
                        <td>{{act.storageTo.name}}</td>
                        <td><button @click="toForm(act)" class="btn btn-sm btn-warning"><i class="fas fa-edit"></i></button></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <b-row>
                <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + data.length }}
                    из {{ meta.total_rows }} записей
                </span>
                </b-col>
                <b-col md="6">
                    <b-pagination
                        v-model="meta.current_page"
                        :total-rows="meta.total_rows"
                        :per-page="meta.per_page"
                        @input="applyFilters"
                        class="justify-content-end"
                    />
                </b-col>
            </b-row>
        </div>
        <div v-else-if="loading" class="alert alert-info text-center">Загрузка...</div>
        <div v-else class="alert alert-info text-center">Нет данных</div>
    </div>
</template>

<script>
    import { MATERIAL_TRANSFER_ACT_ENDPOINT } from "../../utils/endpoints";
    import moment from 'moment'
    import lsfMixin from '../../utils/localStorageFilterMixin'
    import { Datetime } from 'vue-datetime';
    import StoragePicker from '../_common/StoragePicker';

    const FILTER_PATTERN = {
        page: null,
        dateTo: null,
        dateFrom: null,
        storageTo: null,
        storageFrom: null,
        notCanceledOnly: true,
        notReceivedOnly: true,
    };

    export default {
        name: 'MaterialTransferActReceiveList',
        components: {
            datetime: Datetime,
            StoragePicker,
        },
        mixins: [lsfMixin],
        created() {
            this.initFilters(this, FILTER_PATTERN);
        },
        watch: {
            'filters': {
                handler: function () {
                    this.watchFilters(this.filters);
                },
                deep: true,
            },
        },
        data() {
            return {
                filters: {
                    page: null,
                    dateTo: null,
                    dateFrom: null,
                    storageTo: null,
                    storageFrom: null,
                    notReceivedOnly: true,
                    notCanceledOnly: true,
                },
                loading: false,
                data: [],
                meta: {
                    current_page: 1,
                    per_page: 25,
                    total_rows: 0,
                },
            }
        },
        methods: {
            moment,
            clearPage() {
                this.filters.page = 1;
            },
            toForm(act) {
                this.$router.push({name: 'MaterialTransferActReceive', params: {id: act.id}});
            },
            applyFilters() {
                let params = {
                    with: [
                        'storageFrom',
                        'storageTo',
                        'previews',
                    ]
                };
                if (this.filters.dateFrom) {
                    params['filters[dateFrom]'] = moment(this.filters.dateFrom).format('YYYY-MM-DD HH:mm');
                }

                if (this.filters.dateTo) {
                    params['filters[dateTo]'] = moment(this.filters.dateTo).format('YYYY-MM-DD HH:mm');
                }

                if (this.filters.storageFrom) {
                    params['filters[storage_from_ids][]'] = this.filters.storageFrom.id;
                }

                if (this.filters.storageTo) {
                    params['filters[storage_to_ids][]'] = this.filters.storageTo.id;
                }

                if (this.filters.notReceivedOnly) {
                    params['filters[received]'] = 0;
                }

                if (this.filters.notCanceledOnly) {
                    params['filters[canceled]'] = 0;
                }

                if (this.filters.page) {
                    params.page = this.meta.current_page;
                }

                this.loading = true;
                this.$http.get(MATERIAL_TRANSFER_ACT_ENDPOINT, {
                    params: params
                }).then(response => {
                    this.data = response.data.data;
                    this.meta = {
                        total_rows: response.data.total,
                        current_page: response.data.current_page,
                        per_page: response.data.per_page,
                    }

                    this.loading = false;
                }).catch(error => {
                    this.$root.responseError(error)
                    this.loading = false;
                });
            },
        }
    }
</script>

<style>
    .no-border input {
        border: none !important;
    }
</style>
